import { useState, useEffect, useCallback } from "react"
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"
import Numeral from "numeral"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useQueries } from "./useQueries"
import { useCheckoutContext } from "./useCheckout"
import { useLocation } from "./useLocation"
import { useShopContext } from "./useShop"

export const useShopify = () => {
  const client = useApolloClient()
  const { shop } = useShopContext()
  const { storeConfig } = useLocation()
  const {
    helpers: { encodeShopifyId },
  } = useCore()
  const {
    config: { settings },
  } = useApp()
  const { checkout } = useCheckoutContext()

  const isHidden = product => product?.tags?.includes(settings.products.hiddenTag)

  const excludeHidden = products => products?.filter(product => !isHidden(product)) || []

  const edgeNormaliser = object => object?.edges?.map(({ node }) => node) || object || []

  const productWithGroupedOptionsNormaliser = ({ product, grouped: groupedEdges }) => {
    const grouped = edgeNormaliser(groupedEdges)
    const variants = edgeNormaliser(product?.variants)
    const groupedMap = new Map(grouped?.map(variant => [variant.id, variant]))
    const groupedOptionsMap = new Map()

    grouped?.map(node => {
      const variant = groupedMap.get(node.id)
      edgeNormaliser(node.variants).map(variant => variants.push(variant))

      node?.options?.forEach(option => {
        const values = groupedOptionsMap.get(option.name) ? groupedOptionsMap.get(option.name).values : []
        option.values.map(value => {
          if (!values.includes(value)) {
            values.push(value)
          }

          return null
        })
        groupedOptionsMap.set(option.name, {
          ...option,
          values,
        })
      })
      groupedMap.set(node.id, {
        ...variant,
        ...node,
      })

      return null
    })

    const groupedVariantOptions = Array.from(groupedOptionsMap.values()) || []
    const groupedVariantItems = Array.from(groupedMap.values()) || []

    const item = {
      ...(product || {}),
      handle: product?.shopify?.handle || product?.handle,
      grouped: groupedVariantItems,
      options: [
        ...[].concat(...groupedVariantOptions),
        ...[].concat(
          ...(product?.options?.filter(option => !(option.values && option.values.includes(`Default Title`))).map(option => option) || [])
        ),
      ].filter(Boolean),
      variants: [...[].concat(...variants.filter(variant => !(variants?.length > 1 && variant?.title?.includes(`Default Title`))))].filter(Boolean),
    }

    return productNormaliser(item)
  }

  const productNormaliser = product => ({
    ...product,
    images: edgeNormaliser(product?.images)?.map(image => imageNormaliser(image)),
    media: edgeNormaliser(product?.media)?.map(media => mediaNormaliser(media)),
    collections:
      edgeNormaliser(product?.collections)?.map(collection => ({
        ...collection,
        image: imageNormaliser(collection?.image),
      })) || [],
    // metafields: edgeNormaliser(product?.metafields),
    variants:
      edgeNormaliser(product?.variants)?.map(variant => ({
        ...variant,
        image: imageNormaliser(variant?.image),
        // metafields: edgeNormaliser(variant?.metafields),
        priceV2: variant?.priceV2,
        compareAtPriceV2: variant?.compareAtPriceV2,
      })) || [],
  })

  const collectionNormaliser = collection => ({
    ...collection,
    image: imageNormaliser(collection?.image),
    // metafields: edgeNormaliser(collection?.metafields),
    products: edgeNormaliser(collection?.products).map(product => productNormaliser(product)),
  })

  const checkoutNormaliser = checkout => ({
    ...checkout,
    appliedGiftCards: edgeNormaliser(checkout?.appliedGiftCards),
    discountApplications: edgeNormaliser(checkout?.discountApplications),
    lines:
      checkout?.lines?.edges.map(({ node }) => ({
        ...node,
        variant: {
          ...node?.variant,
          image: imageNormaliser(node?.variant?.image),
          priceV2: node?.variant?.priceV2,
          compareAtPriceV2: node?.variant?.compareAtPriceV2,
        },
      })) || [],
  })

  const cartNormaliser = cart => ({
    ...cart,
    lines:
      cart?.lines?.edges.map(({ node }) => ({
        ...node,
        merchandise: {
          ...node?.merchandise,
          image: imageNormaliser(node?.merchandise?.image),
          priceV2: node?.merchandise?.priceV2,
          compareAtPriceV2: node?.merchandise?.compareAtPriceV2,
        },
      })) || [],
  })

  const imageNormaliser = (image, size = null) => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || ``,
    src: imageUrl(image?.originalSrc || image?.src || image?.asset?.url || ``, size),
    srcSet: imageSrcSets(image?.originalSrc || image?.src || image?.asset?.url, size),
  })

  const mediaNormaliser = (media, size = null) => ({
    alt: media?.altText || media?.alt || media?.asset?.alt || ``,
    mediaContentType: media?.mediaContentType,
    sources: media?.sources,
    previewImage: media?.previewImage
      ? {
          alt: media?.altText || media?.alt || media?.asset?.alt || ``,
          src: imageUrl(media?.previewImage.originalSrc, size),
          srcSet: imageSrcSets(media?.previewImage.originalSrc, size),
        }
      : null,
  })

  const priceNormaliser = (presentmentPrices, field, currencyCode) =>
    Object.assign(
      {},
      ...edgeNormaliser(presentmentPrices)
        ?.filter(
          item =>
            item[field]?.currencyCode === (currencyCode || checkout?.currencyCode || shop?.paymentSettings?.currencyCode || storeConfig?.siteCurrency)
        )
        .map(item => ({
          ...item[field],
          local: formatMoney(item[field]?.amount),
        }))
    )

  const adminProductNormaliser = product => ({
    ...product,
    id: encodeShopifyId(product?.id, `Product`),
    variants: product?.variants?.map(variant => ({
      ...variant,
      priceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant), "price"),
      compareAtPriceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant), "compareAtPrice"),
    })),
  })

  const adminPresentmentPriceNormaliser = variant =>
    variant?.presentment_prices?.map(presentmentPrice => ({
      compareAtPrice: presentmentPrice?.compare_at_price
        ? {
            amount: presentmentPrice.compare_at_price.amount,
            currencyCode: presentmentPrice.compare_at_price.currency_code,
          }
        : undefined,
      price: {
        amount: presentmentPrice?.price?.amount,
        currencyCode: presentmentPrice?.price?.currency_code,
      },
    }))

  const formatMoney = (cents: number, currencyCode: string | false, formatType: "0.00" | "0" | "0,0.00" | "0,0"): string => {
    const regex = /\{\{\s*(\w+)\s*\}\}/

    const shopifyFormat = {
      amount: "0.00",
      amount_no_decimals: "0",
      amount_with_comma_separator: "0,0.00",
      amount_no_decimals_with_comma_separator: "0,0",
    }

    const replacement = Numeral(cents).format(formatType)

    return `${
      currencyCode === false ? `` : currencyCode || checkout?.currencyCode || shop?.paymentSettings?.currencyCode || storeConfig?.siteCurrency
    } ${replacement}`.trim()
  }

  const formatTimeline = timeline => {
    if (!timeline) return null

    const days = Number(timeline.replace(settings.products?.delivery?.timelineTag, ""))

    if (typeof days !== "number" || !settings.products?.delivery?.timelines) {
      return timeline
    }

    const period = settings.products?.delivery?.timelines?.find(item => days % item.threshold === 0)

    return `${days / period.threshold} ${period.label}`
  }

  const imageUrl = (src, size = null) => {
    const dimensions = `${size}x${size}`
    const match = src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i)

    return match && src?.includes(`shopify.com`) && size && size !== `master`
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, ``)
      : src?.replace(/http(s)?:/, ``)
  }

  const imageSrcSets = (src, size) =>
    src?.includes(`shopify.com`) &&
    [1, 500, 1000, 1500, 2000]
      .filter(set => !size || (size && size >= set))
      .map(set => `${imageUrl(src, set)} ${set}w`)
      .join(`,`)

  const onSale = (price, compareAtPrice) => compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price)

  const getHandle = item => item?.handle || item?.shopifyHandle || item?.shopify?.shopifyHandle || item?.shopify?.handle

  const useCollections = ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    metafieldIdentifiers = [],
    firstProducts = 0,
    firstVariants = 0,
    handles = [],
  }) => {
    const {
      queries: { GET_COLLECTIONS_BY_HANDLE },
    } = useQueries()
    const [items, setItems] = useState([])

    const fetchItems = async handles => {
      if (handles?.length > 0 && items?.length === 0) {
        const { data } = await client.query({
          query: GET_COLLECTIONS_BY_HANDLE(handles),
          variables: {
            firstCollections,
            firstImages,
            firstMedia,
            metafieldIdentifiers,
            firstProducts,
            firstVariants,
            handles,
          },
        })

        setItems(handles?.map(handle => collectionNormaliser(data[`collection${handle?.replace(/-/g, "")}`])))
      }
    }

    useEffect(() => {
      fetchItems(handles)
    }, [handles])

    return items
  }

  const useProducts = ({ firstCollections = 0, firstImages = 0, firstMedia = 0, metafieldIdentifiers = [], firstVariants = 0, handles = [] }) => {
    const {
      queries: { GET_PRODUCTS_BY_HANDLE },
    } = useQueries()
    const [items, setItems] = useState([])

    const fetchItems = async handles => {
      if (handles?.length > 0 && items?.length === 0) {
        const { data } = await client.query({
          query: GET_PRODUCTS_BY_HANDLE(handles),
          variables: {
            firstCollections,
            firstImages,
            firstMedia,
            metafieldIdentifiers,
            firstVariants,
          },
        })

        setItems(handles?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`])))
      }
    }

    useEffect(() => {
      fetchItems(handles)
    }, [handles])

    return items
  }

  const usePopularProducts = (params = {}) => {
    const {
      queries: { GET_POPULAR_PRODUCTS },
    } = useQueries()
    const { useQuery } = useShopify()
    const [items, setItems] = useState([])

    const { data } = useQuery(GET_POPULAR_PRODUCTS, {
      variables: {
        first: params.first || 50,
        firstImages: params.firstImages || 2,
        firstMedia: params.firstMedia || 0,
        metafieldIdentifiers: [],
        firstCollections: 0,
        firstVariants: params.firstVariants || 5,
      },
    })

    if (data?.products?.edges?.length > 0 && !items.length) {
      const items = excludeHidden(data.products.edges.map(({ node }) => productNormaliser(node)))

      if (items && items.length) {
        setItems(params.max ? items?.slice(0, params.max) : items)
      }
    }

    return items
  }

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    onSale,
    imageUrl,
    imageSrcSets,
    formatMoney,
    formatTimeline,
    edgeNormaliser,
    imageNormaliser,
    checkoutNormaliser,
    adminProductNormaliser,
    priceNormaliser,
    productNormaliser,
    productWithGroupedOptionsNormaliser,
    collectionNormaliser,
    excludeHidden,
    isHidden,
    getHandle,
    useCollections,
    useProducts,
    usePopularProducts,
    cartNormaliser,
  }
}

export const useShopifyProduct = () => {
  const {
    config: {
      settings: { routes },
    },
    activeProduct,
    setActiveProduct,
  } = useApp()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes(routes?.PRODUCT)) {
        if (!activeProduct) setActiveProduct(product)
      } else {
        if (activeProduct !== false) setActiveProduct(false)
      }
    },
    [activeProduct, setActiveProduct]
  )

  return { activeProduct, selectProduct }
}
