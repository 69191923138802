import React from "react"

import { withLayout } from "./withLayout"
import { LayoutGlobal } from "./Global/LayoutGlobal"
import { Header } from "../Header/Header"
import { Footer } from "../Footer/Footer"
import { Wrapper } from "./LayoutStyles"
import { Meta } from "../Meta/Meta"
import { GeoLocationPopup } from "../GeoLocationPopup/GeoLocationPopup"

interface Props {
  active: boolean
  authorised: boolean
  children: any
  data: any
  location: any
  onedaypay: any
  routes: any
}

export const Layout = withLayout(({ active, authorised, children, data, location, onedaypay, routes }: Props) => (
  <>
    <Meta data={data} location={location} routes={routes} />
    {active ? (
      <main>{children}</main>
    ) : onedaypay ? (
      <>
        <LayoutGlobal />
        <main>{children}</main>
      </>
    ) : (
      authorised && (
        <>
          <Wrapper>
            <LayoutGlobal />
            <Header location={location} />
            <main>{children}</main>
          </Wrapper>
          <Footer location={location} />
        </>
      )
    )}
    <GeoLocationPopup />
  </>
))
